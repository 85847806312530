import { Button } from '@mui/material'
import './index.scss'

const AnimatedLetters = ({ letterClass, strArray, idx, callback }) => {
  const doCallback = () => {
    callback()
  }
  return (
    <>
      <span>
        {strArray.map((char, i) => (
          <span key={char + 1} className={`${letterClass} _${i + idx}`}>
            {char}
          </span>
        ))}
      </span>
    </>
  )
}

export default AnimatedLetters
