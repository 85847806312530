import * as React from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineDot from '@mui/lab/TimelineDot'

import Typography from '@mui/material/Typography'
import SchoolIcon from '@mui/icons-material/School'
import WorkIcon from '@mui/icons-material/Work'
import Loader from 'react-loaders'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import TestContext, { TestProvider } from '../Context'

function Header({ isMobile }) {
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)

    // Return a cleanup function to clear the timeout when the component unmounts
    return () => clearTimeout(timeoutId)
  }, [])
  const [letterClass, setLetterClass] = React.useState('text-animate')
  if (isMobile) {
    return null
  }
  return (
    <h1>
      {' '}
      <AnimatedLetters
        letterClass={letterClass}
        strArray={[
          'M',
          'y',
          ' ',
          'S',
          't',
          'o',
          'r',
          'y',
          ' ',
          'S',
          'o',
          ' ',
          'F',
          'a',
          'r',
        ]}
        idx={15}
      />
    </h1>
  )
}

const TimelineChart = () => {
  const [width, setWidth] = React.useState(window.innerWidth)
  const { test, setTest } = React.useContext(TestContext)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  return (
    <>
      {' '}
      <div className="text-zone">
        <Header isMobile={isMobile} />
      </div>
      <div className="timeline-container">
        <Timeline position="alternate">
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0', fontFamily: 'Coolvetica' }}
              align="right"
              variant="body2"
              color="#0489ae"
            >
              May 2022 – May 2024
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot sx={{ background: '#0294CD' }}>
                <WorkIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent
              sx={{
                py: '12px',
                px: 2,
                fontFamily: 'Coolvetica',
                color: '#0489ae',
              }}
            >
              <Typography
                color="#0489ae"
                variant="h5"
                component="span"
                sx={{ fontFamily: 'Coolvetica' }}
              >
                Software Engineer @AnalyticsEngines
              </Typography>
              <Typography
                variant="h6"
                color="#0489ae"
                sx={{
                  fontFamily: 'Coolvetica',
                  color: '#8d8d8d',
                  fontWeight: 300,
                }}
              >
                Typescript | Javascript | Python | SQL development
              </Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0', fontFamily: 'Coolvetica' }}
              variant="body2"
              color="#0489ae"
            >
              May 2021 – May 2022
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot sx={{ background: '#24D6DB' }}>
                <WorkIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent
              sx={{
                py: '12px',
                px: 2,
                fontFamily: 'Coolvetica',
                color: '#0489ae',
              }}
            >
              <Typography
                variant="h6"
                component="span"
                sx={{ fontFamily: 'Coolvetica' }}
                color="#0489ae"
              >
                Software Developer @Exploristics
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontFamily: 'Coolvetica',
                  color: '#8d8d8d',
                  fontWeight: 300,
                }}
              >
                {' '}
                Typescript | Javascript | Python | AWS | SQL development{' '}
              </Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0', fontFamily: 'Coolvetica' }}
              variant="body2"
              color="#0489ae"
            >
              Jan 2020 – Aug 2020
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot sx={{ background: '#0294CD' }}>
                <WorkIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent
              sx={{
                py: '12px',
                px: 2,
                fontFamily: 'Coolvetica',
                color: '#0489ae',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Coolvetica',
                  color: '#0489ae',
                }}
                variant="h6"
                component="span"
              >
                Junior Developer @PlanetPayments
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Coolvetica',
                  color: '#8d8d8d',
                  fontWeight: 300,
                }}
              >
                Typescript | Javascript | C# | .Net Core development{' '}
              </Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0', fontFamily: 'Coolvetica' }}
              variant="body2"
              color="#0489ae"
            >
              Sep 2017 – Sep 2021
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot sx={{ background: '#24D6DB' }}>
                <SchoolIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent
              sx={{
                py: '12px',
                px: 2,
                fontFamily: 'Coolvetica',
                color: '#0489ae',
              }}
            >
              <Typography
                sx={{
                  py: '12px',
                  px: 2,
                  fontFamily: 'Coolvetica',
                  color: '#0489ae',
                }}
                variant="h6"
                component="span"
              >
                BSc Computer Science @University of Galway
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Coolvetica',
                  color: '#8d8d8d',
                  fontWeight: 300,
                }}
              >
                Upper Second Class Honors (2:1)
              </Typography>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </div>{' '}
      <Loader type="pacman" />
    </>
  )
}

export default TimelineChart
